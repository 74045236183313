@use "variables" as *;

.marker-cluster-large, .marker-cluster-medium, .marker-cluster-small {
  background-color: $brand-green;
  z-index: 100;

  div {
    width: 40px;
    height: 40px;
    z-index: 101;
    border-radius: 100%;
    background-color: $brand-white !important;
    border: 5px solid $brand-green;
    position: relative;
    @include body-text;

    color: $brand-green;
    font-weight: 600;
  }
}

.marker-cluster {
  background-color: transparent;

  span {
    display: block;
    height: fit-content;
    width: fit-content;
    @include body-text;
    @include absolute-center;
    color: $brand-green;
    font-weight: 600;

  }
}

.efa-popup {
  border: 1px solid $gray-01;
  border-radius: 2px;

  p {
    margin: 0;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 2px;
  }

  .leaflet-popup-tip-container {
    display: none;
  }
}

a[href="https://leafletjs.com"] {
}

.modal-cluster {
  border: 0;
  border-radius: 2px;

  p {
    margin: 0;
  }

  .leaflet-popup-tip-container {
    display: none;
  }
}


.leaflet-popup-content-wrapper {
  @include respond-to($mobile-xs) {
    max-width: 200px !important;
  }

  border-radius: 8px;
  max-height: 250px;
  overflow-y: auto;

  .leaflet-popup-content {
    width: fit-content !important;
    margin: 0;
  }
}

.est-popup-image {
  padding: 0;
  border-radius: 2px;

  .leaflet-popup-content-wrapper {
    padding: 0;
    border-radius: 2px;
    margin: 0;
    box-shadow: none;
    background-color: transparent;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  img {
    border-radius: 4px;
  }

  .prop-photo {
    width: 200px;
    height: 150px;
    display: block;
    background-color: $brand-white;
    background-size: cover;
    border-radius: 4px;
    position: relative;

    p {
      position: absolute;
      left: 5%;
    }

    p:nth-of-type(1) {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #FFF;
      top: 100px;
    }

    p:nth-of-type(2) {
      font-size: 10px;
      color: #FFF;
      top: 115px;

      &:before {
        content: url("../../assets/image/icons/marker-white.svg");
        margin-right: 5px;
      }
    }

    button.next, button.prev {
      position: absolute;
      height: 100%;
      width: 20%;
      border: 0;

      span {
        color: #FFFFFF;
        font-size: 18px;
        height: 13px;
        width: 7px;
        margin: 0 auto;
        display: block;
      }
    }

    button.prev {
      left: 0;
      background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));

      &:hover {
        background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
      }

      span {
        background-image: url("../../assets/image/icons/slider-arrow-left.svg");
      }
    }

    button.next {
      right: 0;
      background: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));

      span {
        background-image: url("../../assets/image/icons/slider-arrow-right.svg");
      }

      &:hover {
        background: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
      }
    }
  }
}

.labelDefault {
  background: #FFFFFF;
}

.label-marker {
  @include small-text;

  border: 1px solid transparent;
  background-color: $brand-green;
  color: $brand-white;
  padding: 1px 6px;
}

.leaflet-tooltip-top {
  &:before {
    border-top-color: $brand-green;
  }
}

.leaflet-popup-content-wrapper {
  padding: 0;
}

.leaflet-attribution-flag {
  display: none !important;
}
